<template>
  <div class="c-uploader">
    <van-field :name="name" :rules="rules">
      <template #input>
        <!-- <van-icon name="photo-o" /> -->
        <!-- <p class="label">{{ label }}</p> -->
        <!--  :upload-text="label" -->
        <van-uploader v-model="fileList" :before-read="beforeRead" :after-read="afterRead" :max-size="5 * 1024 * 1024"
          @oversize="onOversize" max-count="1" :accept="'image/*'" :upload-icon="uploadIcon" :upload-text="label">
        </van-uploader>
      </template>
    </van-field>
  </div>
</template>
<script>
import { uploadImg } from '@/api/sign'
import Compressor from 'compressorjs';

export default {
  name: 'CUploader',
  props: {
    uploadIconUrl: {
      type: String,
      default: 'photo-o'
    },
    uploadText: {
      type: String,
      default: '上传文件'
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: [String],
      default: ''
    },
    rules: {
      type: [Array],
      default: () => [{ required: true, message: ' ' }]
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: this.value ? [{ url: this.value }] : [] //  [{ url: 'http://dev.juruiyun.com/material/20230509/7c99c36e7b4d4514854349c9f5b25c80.jpg' }] //
    }
  },
  computed: {
    uploadIcon() {
      return this.uploadIconUrl
    }
  },
  methods: {
    onOversize(file) {
      console.log(file);
      this.$toast.fail('文件大小不能超过 5M');
    },
    afterRead(file) {
      const tempFile = file.file
      let _file = tempFile instanceof File ? tempFile : new File([tempFile], tempFile.name, { type: tempFile.type })
      file.status = 'uploading';
      file.message = '上传中...';
      let fd = new FormData()
      fd.append('file', _file)
      uploadImg(fd).then((res) => {
        file.status = 'success'
        file.message = ''
        if (res.success) {
          this.fileList = [{ url: res.content.pictureUrl }]
          this.$emit('input', res.content.pictureUrl)
          this.$emit('change', res.content.pictureUrl)
        }


      }).catch(() => {
        file.status = 'failed';
        file.message = '上传失败';
      })
      return
    },
    beforeRead(file) {
      return this.compressImg(file)
    },
    compressImg(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          success: (res) => {
            resolve(res)
          },
          error(err) {
            console.log(err.message);
          },
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.c-uploader {
  /deep/.van-field__body {
    .van-field__control {
      display: flex;
      justify-content: center;

      // position: relative;
      // top: 10px;
      .van-uploader__upload {
        width: 95%;

        .van-uploader__upload-text {
          // position: absolute;
          color: #666666;
          font-size: 10px;
          font-weight: 600;
          text-align: center;
          // top: -30px;
          width: 120px;
          // margin-right: 5px;
        }
      }

    }
  }
}

/deep/.van-field--error {
  // border: 1px solid #ee0a24 !important;

  .van-uploader__upload-text {
    color: #ee0a24 !important;
  }
}

/deep/ .van-uploader__preview-image {
  width: 150px;
  object-fit: contain !important;
}
</style>
