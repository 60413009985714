<template>
  <div>
    <van-field readonly clickable :label="label" :value="modelText" :placeholder="placeholder || '请选择'"
      @click="showPicker = true" :rules="rules" :name="name" right-icon="arrow-down"/>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="options" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modalValue: this.value,
      showPicker: false,
      modelText: '',
      // columns: [
      //   { text: '杭州', value: 1 },
      //   { text: '宁波', value: 2 },
      //   { text: '温州', value: 3 },
      // ],
    };
  },
  props: {
    options: [Array],
    value: [String, Number],
    placeholder: String,
    label: String,
    rules: [Array],
    name: [String]
  },
  watch: {
    value: {
      handler(val) {
        if (!this.modelText && val && this.options) {
          this.modelText = this.options.filter((res) => val == res.value)[0]?.text
        }
      },
      immediate: true
    },
    options: {
      handler(val) {
        if (!this.modelText && val && this.value) {
          this.modelText = this.options.filter((res) => this.value == res.value)[0]?.text
        }
      },
      immediate: true
    }
  },
  methods: {
    onConfirm(e) {
      console.log(e)
      this.modalValue = e.value;
      console.log(2)
      this.modelText = e.text
      console.log(3)
      this.$emit('input', this.modalValue)
      this.$emit('change', this.modalValue)
      this.showPicker = false;
    },
  },
};
</script>
